<template>
    <div>

        <!-- 表格 -->
        <b-row>

            <b-col lg="12">
                <b-card-actions title="定时列表" @refresh="refreshStop(1)" ref="refreshCard1">
                    <b-row>
                        <b-col lg="5" class="mb-1">
                            <b-button-group>
                                <b-button variant="primary" class="btn-icon" v-b-toggle.sidebar-1
                                    @click="clearfrom"><feather-icon icon="PlusCircleIcon" /></b-button>
                                <!-- <b-button variant="primary" class="btn-icon" @click="selectAllRows()"><feather-icon
                                      icon="CheckCircleIcon" />&nbsp;<span class="align-middle">{{ select_mode }}</span></b-button>
                              <b-button variant="primary" class="btn-icon" @click="deleteAllRows()"><feather-icon
                                      icon="Trash2Icon" />&nbsp;<span class="align-middle">批量删除</span></b-button> -->

                            </b-button-group>

                        </b-col>
                        <!-- <b-col lg="7" class="mb-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text><feather-icon icon="SearchIcon" /></b-input-group-prepend>
                  <b-form-input placeholder="栏目名称" v-model="search_name1" />
                  <b-input-group-append><b-button variant="outline-primary"
                      @click="get_search(1)">检索</b-button></b-input-group-append>
                </b-input-group>
              </b-col> -->
                    </b-row>

                    <b-table ref="selectableTable" class="tabledata" striped hover responsive="sm" :items="items1"
                        :fields="fields" sticky-header no-border-collapse>
                        <template #cell(icon)="data">
                            <feather-icon :icon="data.item.icon" size="20" />
                        </template>
                        <!-- 定时状态 -->
                        <template #cell(status)="data">
                            <b-form-checkbox :checked="data.item.status == '1' ? 'true' : 'false'" aria-colindex="12"
                                @change='changeHand(data)' name="check-button" switch inline>
                            </b-form-checkbox>
                        </template>
                        <!-- 定时类型 -->
                        <template #cell(type)="data">

                            <!-- <span v-if="data.item.status == '0'">关闭</span>
                                <span v-if="data.item.status == '1'">开启</span> -->
                            <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.flg)}`"
                                class="text-capitalize">
                                <span v-if="data.item.type == 0">手动类型</span>
                                <span v-if="data.item.type == 1">节假日类型</span>
                                <span v-if="data.item.type == 2">平日类型</span>
                                <span v-if="data.item.type == 3">单次类型</span>
                                <span v-if="data.item.type == 4">深夜类型</span>
                              
                            </b-badge>

                        </template>
                        <!-- 设置 -->
                        <template #cell(actions)="data">
                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                </template>

                                <!-- <b-dropdown-item @click="get_edit(data.item)" v-b-toggle.sidebar-1>
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">编辑</span>
                  </b-dropdown-item> -->

                                <b-dropdown-item @click="get_del(data.item.id)">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">删除</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>


                    </b-table>



                </b-card-actions>
            </b-col>
        </b-row>
        <!-- 表单 -->
        <b-sidebar id="sidebar-1" shadow bg-variant="white" right backdrop width="40%" :title="titledata">
            <b-card class="add_card">
                <b-form>
                    <validation-observer ref="simpleRules">
                        <b-row>
                            <b-col cols="6">
                                <!-- show-seconds,加秒 -->
                                <b-form-group label="开始时间" label-for="h-start_time" label-cols-md="4">
                                    <validation-provider #default="{ errors }" name="start_time" rules="required">
                                        <b-form-timepicker id="timepicker-placeholder" show-seconds v-model='FormData.start_time1'
                                            placeholder="开始时间" local="en" />
                                    </validation-provider>
                                </b-form-group>

                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="结束时间" label-for="h-end_time" label-cols-md="4">
                                    <validation-provider #default="{ errors }" name="end_time" rules="required">
                                        <b-form-timepicker id="timepicker-placeholder1" show-seconds v-model='FormData.end_time1'
                                            placeholder="结束时间" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label="第二段开始时间" label-for="h-start_time" label-cols-md="4">
                                    <validation-provider #default="{ errors }" name="start_time" rules="required">
                                        <b-form-timepicker id="timepicker-placeholder2" show-seconds v-model='FormData.start_time2'
                                            placeholder="第二段开始时间" local="en" />
                                    </validation-provider>
                                </b-form-group>

                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="第二段结束时间" label-for="h-end_time" label-cols-md="4">
                                    <validation-provider #default="{ errors }" name="end_time" rules="required">
                                        <b-form-timepicker id="timepicker-placeholder3" show-seconds v-model='FormData.end_time2'
                                            placeholder="第二段结束时间" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>


                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="控制器主题" label-for="h-password" label-cols-md="4">
                                    <validation-provider #default="{ errors }" name="menu_icon" rules="required">
                                        <!-- <b-form-input v-model="FormData.topic" id="h-password" placeholder="请输入控制器主题"
                                            :state="errors.length > 0 ? false : null" /> -->
                                        <v-select v-model="topicData" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            label="name" :options="topicOption" :reduce="val => val" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="开启" label-for="h-duration_random" label-cols-md="4">
                                    <b-form-group>
                                        <v-select v-model="openData" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            label="title" :options="opneOption" multiple />
                                    </b-form-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="定时类型" label-for="h-duration_random" label-cols-md="4">
                                    <b-form-group>
                                        <v-select v-model="typeData" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            label="title" :options="typeOption" />
                                    </b-form-group>
                                </b-form-group>
                            </b-col>
                        </b-row>



                        <b-row>
                            <b-col cols="12" style="text-align:center">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" style="margin-top: 100px;"
                                    variant="primary" class="mr-1" @click="save()">
                                    保存
                                </b-button>
                            </b-col>


                        </b-row>
                    </validation-observer>
                </b-form>
            </b-card>
        </b-sidebar>
        <!-- 弹窗 -->
        <b-modal id="modal-center" centered title="手动启动" ok-only ok-title="确定">
            <div>
                <b-card-text class="mb-0">
                    是否开启
                </b-card-text>
                <b-form-checkbox checked="false" @change="swichData($event)" class="custom-control-success"
                    name="check-button" switch />
            </div>
        </b-modal>
    </div>
</template>
  
<script>
import {
    BCard, BBadge, BCardText, BPagination, BButton, BPopover, BTable, BRow, BCol, BDropdown, BDropdownItem, BSidebar, VBToggle, BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTags,
    BInputGroupAppend, BButtonGroup,
    BInputGroup, BInputGroupPrepend, BFormSelect,
    BFormRating, BFormRadio, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BListGroupItem, BAvatar } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import qs from 'qs'

export default {
    components: {
        BFormSelect,
        BBadge,
        BSidebar,
        VBToggle,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BTable,
        BCard,
        BCardText,
        BCardActions,
        BButton,
        BPopover,
        BPagination,
        AppCollapse,
        AppCollapseItem,
        draggable,
        BListGroupItem,
        BAvatar,
        Prism,
        vSelect,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormTags,
        BInputGroupAppend,
        BButtonGroup,
        BInputGroup,
        BInputGroupPrepend,
        ToastificationContent,
        VuePerfectScrollbar,
        ValidationProvider,
        ValidationObserver,
        BFormRating, BFormRadio, BFormDatepicker, BFormTimepicker,


    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID', },
                { key: 'open', label: '开启中', },
                { key: 'close', label: '关闭中', },
                { key: 'start_time1', label: '开始时间', },
                { key: 'end_time1', label: '结束时间', },
                { key: 'start_time2', label: '开始时间', },
                { key: 'end_time2', label: '结束时间', },
                { key: 'gname', label: '控制器名称', },
                { key: 'topic', label: '控制器主题', },
                { key: 'type', label: '定时类型', },
                { key: 'status', label: '定时状态', },
                { key: 'actions', label: '设置', },

            ],
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
                wheelPropagation: false,
            },
            planOptions: [
                { label: '隐藏', value: '0' },
                { label: '显示', value: '1' },
            ],
            status_name: '',
            // status:true,
            items1: [],
            table_height: 400,
            currentPage: 1,
            perPage: 20,
            rows: 0,
            list: [],
            // selected: [],

            select_all: 0,
            select_mode: "全选",
            nameFilter: '',
            roleFilter: '',
            planFilter: '',
            search_name1: '',
            search_name2: '',
            detailstData: {},
            editData: {},
            // pid: 0,

            FormData: {
                act: '',
                start_time1: '',
                end_time1: '',
                start_time2: '',
                end_time2: '',
                open: '',
                close: '',
                topic1: '',
                gate_id: '',
                type: ""
            },
            state: true,
            options: [],
            titledata: '',
            selected: {},
            option1: [{ title: '每天', id: 0 }, { title: '每周', id: 1 }, { title: '每月', id: 2 }],
            workData: [],


            timeValue: '',
            openData: [],
            closeData: [],
            opneOption: [{ title: '1号路灯', id: 'A01' },
            { title: '2号路灯', id: 'A02' },
            { title: '3号路灯', id: 'A03' },
            { title: '4号路灯', id: 'A04' },
            { title: '5号路灯', id: 'A05' },
            { title: '6号路灯', id: 'A06' },
            { title: '7号路灯', id: 'A07' },
            { title: '8号路灯', id: 'A08' },
            { title: '9号路灯', id: 'A09' },
            { title: '10号路灯', id: 'A10' },
            { title: '11号路灯', id: 'A11' },
            { title: '12号路灯', id: 'A12' },
            { title: '13号路灯', id: 'A13' },
            { title: '14号路灯', id: 'A14' },
            { title: '15号路灯', id: 'A15' },
            { title: '16号路灯', id: 'A16' },
            ],
            typeData: '',
            typeOption: [
            { title: '手动', id: '0'},
            { title: '节假日', id: '1' },
            { title: '平日', id: '2' },
            { title: '单次', id: '3' },
            { title: '深夜', id: '4' },
            ],

            list1: [],
            list2: [],
            dataList1: [],
            dataList2: [],
            dataVal: 0,
            idValue: '',
            ids: '',
            monthData: [
                {
                    id: '1',
                    name: '1号'
                },
                {
                    id: '2',
                    name: '2号'
                },
                {
                    id: '3',
                    name: '3号'
                },

                {
                    id: '4',
                    name: '4号'
                },

                {
                    id: '5',
                    name: '5号'
                },
                {
                    id: '6',
                    name: '6号'
                },


                {
                    id: '7',
                    name: '7号'
                },
                {
                    id: '8',
                    name: '8号'
                },
                {
                    id: '9',
                    name: '9号'
                },

                {
                    id: '10',
                    name: '10号'
                },
                {
                    id: '11',
                    name: '11号'
                },
                {
                    id: '12',
                    name: '12号'
                },


                {
                    id: '13',
                    name: '13号'
                },
                {
                    id: '13',
                    name: '13号'
                },
                {
                    id: '14',
                    name: '14号'
                },
                {
                    id: '15',
                    name: '15号'
                },
                {
                    id: '16',
                    name: '16号'
                },
                {
                    id: '17',
                    name: '17号'
                },

                {
                    id: '18',
                    name: '18号'
                },

                {
                    id: '19',
                    name: '19号'
                },
                {
                    id: '20',
                    name: '20号'
                },


                {
                    id: '21',
                    name: '21号'
                },
                {
                    id: '22',
                    name: '22号'
                },
                {
                    id: '23',
                    name: '23号'
                },

                {
                    id: '24',
                    name: '24号'
                },
                {
                    id: '25',
                    name: '25号'
                },
                {
                    id: '26',
                    name: '26号'
                },


                {
                    id: '27',
                    name: '27号'
                },
                {
                    id: '28',
                    name: '28号'
                },
                {
                    id: '29',
                    name: '29号'
                },
                {
                    id: '30',
                    name: '30号'
                },
                {
                    id: '31',
                    name: '31号'
                },
            ],
            topicData: '',
            topicOption: [],
            status_id: ''
        }
    },
    created() {

    },
    watch: {
        selected: function (oldV, newV) {

            this.dataVal = oldV

        },

    },
    mounted() {
        this.get_data()
        this.get_list2() //音频
        this.get_list1() //设备
        this.get_info()
    },
    methods: {
        changeHand(e) {
            console.log(e, '1123233');
            if (e.item.status == '0') {
                this.status_id = 1
            } else {
                this.status_id = 0
            }
            let data = e.item.id
            this.get_change(data)
        },
        get_change(val_id) {
            let dataObj = {
                act: "edit",
                id: val_id,
                status: this.status_id
            }
            this.$http.post('/svr/yunhu_task.php', dataObj)
                .then(res => {
                    console.log(res, '1211');
                    if (res.code == 20000) {
                        this.showToast('success', '状态设置成功')
                        this.get_data()
                    }
                })
        },
        get_info() {
            let dataObj = {
                act: "gate_info",
            }
            this.$http.post('/svr/lamp_gateway.php', dataObj)
                .then(res => {
                    this.topicOption = res.data
                    console.log(this.topicOption, 'weghjeqweb');
                })
        },
        get_list1() {
            let dataObj = {
                act: "lst",
                pge: 1,
                cnt: 50,
            }
            this.$http.post('/svr/boardcast_device.php', dataObj)
                .then(res => {
                    this.list1 = res.data.lst
                })
        },
        get_list2() {
            let dataObj = {
                act: "lst",
                pge: 1,
                cnt: 50,

            }
            this.$http.post('/svr/boardcast_voice.php', dataObj)
                .then(res => {
                    this.dataList1 = res.data.lst
                })
        },
        clearfrom() {
            this.state = true
            this.titledata = ''
            this.FormData = {
                act: '',
                start_time1: '',
                end_time1: '',
                start_time2: '',
                end_time2: '',
                open: '',
                close: '',
                topic1: '',
                gate_id: '',
                type: ""
            }
            this.openData = []
            this.closeData = []
            this.workData = []

        },

        get_search(type) {
            if (type == 1) {
                this.get_data(1)
            } else {
                this.get_data(2)
            }
        },
        getStause(val) {
            console.log(val, '22');
            this.planFilter = val
        },
        refreshStop(type) {
            if (type == 1) {
                this.search_name1 = ""
                this.get_data(1)
            } else {
                this.search_name2 = ""
                this.get_data(2)
            }
        },

        del_data(ids) {
            let dataObj = {
                act: "del",
                id: ids,
            }
            this.$http.post('/svr/yunhu_task.php', dataObj)
                .then(res => {
                    this.showToast('success', '删除成功')
                    this.get_data()
                })
        },
        deleteAllRows(id) {
            this.$swal({
                title: '确定删除数据吗?',
                text: "此操作将不可逆,请谨慎操作",
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '我确定!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.del_data(id)
                }
            })
        },
        showToast(variant, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: '系统提示',
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },


        get_data() {

            let dataObj = {
                act: "lst",
                pge: 0,
                cnt: 10,
            }
            this.$refs["refreshCard1"].showLoading = true
            this.$http.post('/svr/yunhu_task.php', dataObj)
                .then(res => {
                    // console.log(res, '数据');
                    this.items1 = res.data.lst
                    // this.items1.map(e => {
                    //     e.type_set.split("")
                    //     console.log(e.type_set, 'zhouqi');
                    // })

                    this.$refs["refreshCard1"].showLoading = false
                })
        },
        get_del(val) {
            this.deleteAllRows(val)
        },
        //   get_edit(e) {

        //     this.FormData = e
        //     this.FormData.duration_time = e.duration
        //     this.selected2.id = this.FormData.random
        //     let hours = Math.floor(this.FormData.duration_time / 3600);
        //     let min = Math.floor((this.FormData.duration_time % 3600) / 60);
        //     let s = parseInt(this.FormData.duration_time % 3600) % 60;
        //     let hh = hours < 10 ? '0' + hours : hours;
        //     let mm = min < 10 ? '0' + min : min;
        //     let ss = s < 10 ? '0' + s : s;
        //     // return hh + ":" + mm + ":" + ss;
        //     this.timeValue = hh + ":" + mm + ":" + ss
        //     console.log(this.FormData);
        //     console.log(this.timeValue);

        //     // 音频和设备以及周期...(111)
        //   },
        save() {


            let opens = []
            this.openData.map(e => {
                opens.push(e.id)
            })
            this.FormData.open = opens.toString()
            let closes = []
            let closeData = this.opneOption.filter((item) => !this.openData.some(e => e === item))
            closeData.map(e => {
                closes.push(e.id)
            })
            this.FormData.close = closes.toString()
            this.FormData.gate_id = this.topicData.gate_id
            this.FormData.topic1 = this.topicData.topic1
            this.FormData.type = this.typeData.id


            console.log(this.FormData, '保存数据');
            this.$refs.simpleRules.validate().then(success => {
                if (success) {

                    this.FormData.act = 'add'

                    this.$http.post('/svr/yunhu_task.php', this.FormData).then(res => {
                        if (res.code == 20000) {
                            this.showToast('success', '添加成功!')
                            this.get_data()
                        }
                    })
                }
            })
        },




        resolveUserStatusVariant(state) {
            if (state === '0') return 'warning'
            if (state === '1') return 'success'
            if (state === '2') return 'primary'
            return 'primary'
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.list-group-item {
    transition: all 1s
}

.tabledata {
    border-radius: 3px;
}

.b-table-sticky-header {
    max-height: calc(100vh - 390px) !important;
}

.custom-select {
    padding: 0.438rem 0rem 0.438rem 0.6rem;
    width: 3.4rem;
}

.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}

.filter {
    margin-bottom: 2rem;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.1rem;
    font-weight: 600;
}

.card {
    margin-bottom: 0 !important;
}

.menu_main {
    height: calc(100vh - 328px) !important;
}

.add_card {
    height: calc(100vh - 70px) !important;
}
</style>
  
  